import React from 'react';
import './App.css';

function App() {
   return (
      <div className="App">
         <div className="header">
            FRIMANO ACCOUNTING SERVICES SRL
         </div>
         <div className="content">
            Bienvenidos a Frimano Accounting Services. Somos una firma de contadores especializados en...
            {/* Aquí puedes agregar más contenido */}
         </div>
      </div>
   );
}

export default App;